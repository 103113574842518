<template>
  <div class="editor-guide">
    <div class="wrapper">
      <div class="title font-md">
        <span>에디터 사용법</span>
      </div>
      <div class="content">
        <ul class="tight font-sm">
          <li>
            <button aria-label="전체 화면" title="전체 화면" type="button" tabindex="-1" class="tox-tbtn" disabled="disabled">
							<span class="tox-icon tox-tbtn__icon-wrap"><svg width="24" height="24">
								<path
                    d="M15.3 10l-1.2-1.3 2.9-3h-2.3a.9.9 0 1 1 0-1.7H19c.5 0 .9.4.9.9v4.4a.9.9 0 1 1-1.8 0V7l-2.9 3zm0 4l3 3v-2.3a.9.9 0 1 1 1.7 0V19c0 .5-.4.9-.9.9h-4.4a.9.9 0 1 1 0-1.8H17l-3-2.9 1.3-1.2zM10 15.4l-2.9 3h2.3a.9.9 0 1 1 0 1.7H5a.9.9 0 0 1-.9-.9v-4.4a.9.9 0 1 1 1.8 0V17l2.9-3 1.2 1.3zM8.7 10L5.7 7v2.3a.9.9 0 0 1-1.7 0V5c0-.5.4-.9.9-.9h4.4a.9.9 0 0 1 0 1.8H7l3 2.9-1.3 1.2z"
                    fill-rule="nonzero"></path>
							</svg></span>
            </button>
            <span class="description">전체 화면</span>
          </li>
          <li>
            <button aria-label="실행 취소" title="실행 취소" type="button" tabindex="-1" class="tox-tbtn" disabled="disabled">
							<span class="tox-icon tox-tbtn__icon-wrap"><svg width="24" height="24">
								<path d="M6.4 8H12c3.7 0 6.2 2 6.8 5.1.6 2.7-.4 5.6-2.3 6.8a1 1 0 0 1-1-1.8c1.1-.6 1.8-2.7 1.4-4.6-.5-2.1-2.1-3.5-4.9-3.5H6.4l3.3 3.3a1 1 0 1 1-1.4 1.4l-5-5a1 1 0 0 1 0-1.4l5-5a1 1 0 0 1 1.4 1.4L6.4 8z"
                      fill-rule="nonzero"></path>
							</svg></span>
            </button>
            <span class="description">실행 취소</span>
          </li>
          <li>
            <button aria-label="다시 실행" title="다시 실행" type="button" tabindex="-1" class="tox-tbtn" disabled="disabled">
							<span class="tox-icon tox-tbtn__icon-wrap"><svg width="24" height="24">
								<path d="M17.6 10H12c-2.8 0-4.4 1.4-4.9 3.5-.4 2 .3 4 1.4 4.6a1 1 0 1 1-1 1.8c-2-1.2-2.9-4.1-2.3-6.8.6-3 3-5.1 6.8-5.1h5.6l-3.3-3.3a1 1 0 1 1 1.4-1.4l5 5a1 1 0 0 1 0 1.4l-5 5a1 1 0 0 1-1.4-1.4l3.3-3.3z"
                      fill-rule="nonzero"></path>
							</svg></span>
            </button>
            <span class="description">다시 실행</span>
          </li>
          <li>
            <button title="서식" aria-label="서식" aria-haspopup="true" type="button" unselectable="on" tabindex="-1" class="tox-tbtn tox-tbtn--select tox-tbtn--bespoke" aria-expanded="false" style="user-select: none;">
              <span class="tox-tbtn__select-label">단락</span>
              <div class="tox-tbtn__select-chevron" style="display: inline-block;">
                <svg width="10" height="10">
                  <path d="M8.7 2.2c.3-.3.8-.3 1 0 .4.4.4.9 0 1.2L5.7 7.8c-.3.3-.9.3-1.2 0L.2 3.4a.8.8 0 0 1 0-1.2c.3-.3.8-.3 1.1 0L5 6l3.7-3.8z" fill-rule="nonzero"></path>
                </svg>
              </div>
            </button>
            <span class="description">제목 및 본문 등 글의 종류에 맞게 글자 크기 조정</span>
          </li>
          <li>
            <button aria-pressed="false" aria-label="문자 색깔" title="문자 색깔" role="button" aria-haspopup="true" tabindex="-1" class="tox-split-button" aria-expanded="false" aria-describedby="aria_6510321431401569472838753"
                    style="display: inline-block;">
							<span role="presentation" tabindex="-1" class="tox-tbtn">
								<span class="tox-icon tox-tbtn__icon-wrap"><svg width="24" height="24"><g fill-rule="evenodd">
									<path id="tox-icon-text-color__color" d="M3 18h18v3H3z"></path>
									<path
                      d="M8.7 16h-.8a.5.5 0 0 1-.5-.6l2.7-9c.1-.3.3-.4.5-.4h2.8c.2 0 .4.1.5.4l2.7 9a.5.5 0 0 1-.5.6h-.8a.5.5 0 0 1-.4-.4l-.7-2.2c0-.3-.3-.4-.5-.4h-3.4c-.2 0-.4.1-.5.4l-.7 2.2c0 .3-.2.4-.4.4zm2.6-7.6l-.6 2a.5.5 0 0 0 .5.6h1.6a.5.5 0 0 0 .5-.6l-.6-2c0-.3-.3-.4-.5-.4h-.4c-.2 0-.4.1-.5.4z"></path>
								</g></svg></span>
							</span>
              <span role="presentation" tabindex="-1" class="tox-tbtn tox-split-button__chevron">
								<svg width="10" height="10">
									<path d="M8.7 2.2c.3-.3.8-.3 1 0 .4.4.4.9 0 1.2L5.7 7.8c-.3.3-.9.3-1.2 0L.2 3.4a.8.8 0 0 1 0-1.2c.3-.3.8-.3 1.1 0L5 6l3.7-3.8z" fill-rule="nonzero"></path>
								</svg>
							</span>
            </button>
            <span class="description" style="display: inline-block;">글자 색깔 변경</span>
          </li>
          <li>
            <button aria-label="굵게" title="굵게" type="button" tabindex="-1" class="tox-tbtn" aria-pressed="false">
							<span class="tox-icon tox-tbtn__icon-wrap"><svg width="24" height="24">
								<path
                    d="M7.8 19c-.3 0-.5 0-.6-.2l-.2-.5V5.7c0-.2 0-.4.2-.5l.6-.2h5c1.5 0 2.7.3 3.5 1 .7.6 1.1 1.4 1.1 2.5a3 3 0 0 1-.6 1.9c-.4.6-1 1-1.6 1.2.4.1.9.3 1.3.6s.8.7 1 1.2c.4.4.5 1 .5 1.6 0 1.3-.4 2.3-1.3 3-.8.7-2.1 1-3.8 1H7.8zm5-8.3c.6 0 1.2-.1 1.6-.5.4-.3.6-.7.6-1.3 0-1.1-.8-1.7-2.3-1.7H9.3v3.5h3.4zm.5 6c.7 0 1.3-.1 1.7-.4.4-.4.6-.9.6-1.5s-.2-1-.7-1.4c-.4-.3-1-.4-2-.4H9.4v3.8h4z"
                    fill-rule="evenodd"></path>
							</svg></span>
            </button>
            <span class="description">글자 굵게하기</span>
          </li>
          <li>
            <button aria-label="기울임꼴" title="기울임꼴" type="button" tabindex="-1" class="tox-tbtn" aria-pressed="false">
							<span class="tox-icon tox-tbtn__icon-wrap"><svg width="24" height="24">
								<path d="M16.7 4.7l-.1.9h-.3c-.6 0-1 0-1.4.3-.3.3-.4.6-.5 1.1l-2.1 9.8v.6c0 .5.4.8 1.4.8h.2l-.2.8H8l.2-.8h.2c1.1 0 1.8-.5 2-1.5l2-9.8.1-.5c0-.6-.4-.8-1.4-.8h-.3l.2-.9h5.8z" fill-rule="evenodd"></path>
							</svg></span>
            </button>
            <span class="description">글자 기울이기</span>
          </li>
          <li>
            <button aria-label="밑줄" title="밑줄" type="button" tabindex="-1" class="tox-tbtn" aria-pressed="false">
							<span class="tox-icon tox-tbtn__icon-wrap"><svg width="24" height="24">
								<path
                    d="M16 5c.6 0 1 .4 1 1v5.5a4 4 0 0 1-.4 1.8l-1 1.4a5.3 5.3 0 0 1-5.5 1 5 5 0 0 1-1.6-1c-.5-.4-.8-.9-1.1-1.4a4 4 0 0 1-.4-1.8V6c0-.6.4-1 1-1s1 .4 1 1v5.5c0 .3 0 .6.2 1l.6.7a3.3 3.3 0 0 0 2.2.8 3.4 3.4 0 0 0 2.2-.8c.3-.2.4-.5.6-.8l.2-.9V6c0-.6.4-1 1-1zM8 17h8c.6 0 1 .4 1 1s-.4 1-1 1H8a1 1 0 0 1 0-2z"
                    fill-rule="evenodd"></path>
							</svg></span>
            </button>
            <span class="description">글자 밑줄치기</span>
          </li>
          <li>
            <button aria-label="취소선" title="취소선" type="button" tabindex="-1" class="tox-tbtn" aria-pressed="false">
							<span class="tox-icon tox-tbtn__icon-wrap"><svg width="24" height="24"><g fill-rule="evenodd">
								<path
                    d="M15.6 8.5c-.5-.7-1-1.1-1.3-1.3-.6-.4-1.3-.6-2-.6-2.7 0-2.8 1.7-2.8 2.1 0 1.6 1.8 2 3.2 2.3 4.4.9 4.6 2.8 4.6 3.9 0 1.4-.7 4.1-5 4.1A6.2 6.2 0 0 1 7 16.4l1.5-1.1c.4.6 1.6 2 3.7 2 1.6 0 2.5-.4 3-1.2.4-.8.3-2-.8-2.6-.7-.4-1.6-.7-2.9-1-1-.2-3.9-.8-3.9-3.6C7.6 6 10.3 5 12.4 5c2.9 0 4.2 1.6 4.7 2.4l-1.5 1.1z"></path>
								<path d="M5 11h14a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2z" fill-rule="nonzero"></path>
							</g></svg></span>
            </button>
            <span class="description">글자 취소선 긋기(리워드 원가, 종료된 리워드, 잘못된 내용 표기)</span>
          </li>
          <li>
            <button aria-label="위 첨자" title="위 첨자" type="button" tabindex="-1" class="tox-tbtn" aria-pressed="false">
							<span class="tox-icon tox-tbtn__icon-wrap"><svg width="24" height="24">
								<path
                    d="M15 9.4L10.4 14l4.6 4.6-1.4 1.4L9 15.4 4.4 20 3 18.6 7.6 14 3 9.4 4.4 8 9 12.6 13.6 8 15 9.4zm5.9 1.6h-5v-1l1-.8 1.7-1.6c.3-.5.5-.9.5-1.3 0-.3 0-.5-.2-.7-.2-.2-.5-.3-.9-.3l-.8.2-.7.4-.4-1.2c.2-.2.5-.4 1-.5.3-.2.8-.2 1.2-.2.8 0 1.4.2 1.8.6.4.4.6 1 .6 1.6 0 .5-.2 1-.5 1.5l-1.3 1.4-.6.5h2.6V11z"
                    fill-rule="nonzero"></path>
							</svg></span>
            </button>
            <button aria-label="아래 첨자" title="아래 첨자" type="button" tabindex="-1" class="tox-tbtn" aria-pressed="false">
							<span class="tox-icon tox-tbtn__icon-wrap"><svg width="24" height="24">
								<path
                    d="M10.4 10l4.6 4.6-1.4 1.4L9 11.4 4.4 16 3 14.6 7.6 10 3 5.4 4.4 4 9 8.6 13.6 4 15 5.4 10.4 10zM21 19h-5v-1l1-.8 1.7-1.6c.3-.4.5-.8.5-1.2 0-.3 0-.6-.2-.7-.2-.2-.5-.3-.9-.3a2 2 0 0 0-.8.2l-.7.3-.4-1.1 1-.6 1.2-.2c.8 0 1.4.3 1.8.7.4.4.6.9.6 1.5s-.2 1.1-.5 1.6a8 8 0 0 1-1.3 1.3l-.6.6h2.6V19z"
                    fill-rule="nonzero"></path>
							</svg></span>
            </button>
            <span class="description">윗첨자, 아래첨자 (글자 크기 최소)</span>
          </li>
          <li>
            <button aria-label="가로" title="가로" type="button" tabindex="-1" class="tox-tbtn">
              <span class="tox-icon tox-tbtn__icon-wrap"><svg width="24" height="24"><path d="M4 11h16v2H4z" fill-rule="evenodd"></path></svg></span>
            </button>
            <span class="description">가로줄 넣기 (내용이 달라지는 구획 나눌 시 활용)</span>
          </li>
          <li>
            <button aria-label="인용문" title="인용문" type="button" tabindex="-1" class="tox-tbtn" aria-pressed="false">
							<span class="tox-icon tox-tbtn__icon-wrap"><svg width="24" height="24">
								<path d="M7.5 17h.9c.4 0 .7-.2.9-.6L11 13V8c0-.6-.4-1-1-1H6a1 1 0 0 0-1 1v4c0 .6.4 1 1 1h2l-1.3 2.7a1 1 0 0 0 .8 1.3zm8 0h.9c.4 0 .7-.2.9-.6L19 13V8c0-.6-.4-1-1-1h-4a1 1 0 0 0-1 1v4c0 .6.4 1 1 1h2l-1.3 2.7a1 1 0 0 0 .8 1.3z"
                      fill-rule="nonzero"></path>
							</svg></span>
            </button>
            <span class="description">따옴표 입력 및 가운데 정렬 (강조가 필요한 내용 입력 시 활용)</span>
          </li>
          <li>
            <button aria-label="링크 삽입/수정" title="링크 삽입/수정" type="button" tabindex="-1" class="tox-tbtn" aria-pressed="false">
							<span class="tox-icon tox-tbtn__icon-wrap"><svg width="24" height="24">
								<path
                    d="M6.2 12.3a1 1 0 0 1 1.4 1.4l-2.1 2a2 2 0 1 0 2.7 2.8l4.8-4.8a1 1 0 0 0 0-1.4 1 1 0 1 1 1.4-1.3 2.9 2.9 0 0 1 0 4L9.6 20a3.9 3.9 0 0 1-5.5-5.5l2-2zm11.6-.6a1 1 0 0 1-1.4-1.4l2-2a2 2 0 1 0-2.6-2.8L11 10.3a1 1 0 0 0 0 1.4A1 1 0 1 1 9.6 13a2.9 2.9 0 0 1 0-4L14.4 4a3.9 3.9 0 0 1 5.5 5.5l-2 2z"
                    fill-rule="nonzero"></path>
							</svg></span>
            </button>
            <span class="description">링크 삽입/수정 (주소 이동이 필요한 링크 입력)</span>
          </li>
          <li>
            <button aria-label="미디어 삽입/수정" title="미디어 삽입/수정" type="button" tabindex="-1" class="tox-tbtn" aria-pressed="false">
							<span class="tox-icon tox-tbtn__icon-wrap"><svg width="24" height="24">
								<path d="M4 3h16c.6 0 1 .4 1 1v16c0 .6-.4 1-1 1H4a1 1 0 0 1-1-1V4c0-.6.4-1 1-1zm1 2v14h14V5H5zm4.8 2.6l5.6 4a.5.5 0 0 1 0 .8l-5.6 4A.5.5 0 0 1 9 16V8a.5.5 0 0 1 .8-.4z" fill-rule="nonzero"></path>
							</svg></span>
            </button>
            <span class="description">영상 자료 업로드 (소스에 공유 링크 혹은 삽입에 코드 입력)</span>
          </li>
          <li>
            <button aria-label="이미지 삽입/수정" title="이미지 삽입/수정" type="button" tabindex="-1" class="tox-tbtn" aria-pressed="false">
							<span class="tox-icon tox-tbtn__icon-wrap"><svg width="24" height="24">
								<path
                    d="M5 15.7l3.3-3.2c.3-.3.7-.3 1 0L12 15l4.1-4c.3-.4.8-.4 1 0l2 1.9V5H5v10.7zM5 18V19h3l2.8-2.9-2-2L5 17.9zm14-3l-2.5-2.4-6.4 6.5H19v-4zM4 3h16c.6 0 1 .4 1 1v16c0 .6-.4 1-1 1H4a1 1 0 0 1-1-1V4c0-.6.4-1 1-1zm6 8a2 2 0 1 0 0-4 2 2 0 0 0 0 4z"
                    fill-rule="nonzero"></path>
							</svg></span>
            </button>
            <span class="description">이미지 삽입/수정(일반 - 캡션 표시 체크 시 이미지명 입력 가능)</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent} from "@vue/composition-api";
import mixin from "@/scripts/mixin";
import store from "@/scripts/store";

function Component(initialize) {
  this.name = "modalEditorGuide";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  setup() {
    const component = new Component(() => {
      store.commit("tuneModal", {component, size: "md"});
    });

    return {component};
  }
});
</script>

<style lang="scss" scoped>
.editor-guide {
  > .wrapper {
    background: #fff;
    padding: $px25;

    .title {
      margin-bottom: $px25;
    }

    .content {
      ul > li {
        padding-bottom: $px15;

        > button {
          background: none;
          border: 0;
          outline: none;
          padding: 0;
          margin-right: $px5;
          margin-bottom: $px5;
        }

        > span {
          margin-left: $px5;
        }

        &:last-child {
          padding-bottom: 0;
        }
      }
    }
  }
}
</style>